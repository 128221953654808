<template>
  <div class="big">
    <p class="title-head">选择参与作品</p>
    <p class="title-radio">
      <el-radio  v-if="song" v-model="radio" label="2">歌曲</el-radio>
      <el-radio v-if='demo' v-model="radio" label="1">词曲</el-radio>
    </p>
     <div style="width:572px;margin:0px auto; text-align:right;margin-bottom:5px;color:red; font-size:10px;"> <span v-if="totalnum>10" @click='change' style="cursor:pointer"> <i class="el-icon-refresh"></i> 换一批</span></div>
    <ul class="center">
      <li
        class="item"
        :class="index == flag ? 'active' : ''"
        @click="choice(item, index)"
        v-for="(item, index) in songlist"
        :key="index"
      >
        <div class="horn"></div>
        <p>{{ (index+1)+((params.pageNum-1)*10) }}.{{ item.name }}</p>
        <p v-if="radio == 2">专辑：{{ item.singer }}</p>
        <p v-else>{{item.lyricist}}</p>
      </li>
    </ul>
    <div class="footbtn">
      <button @click="$router.replace('/activity')">返回</button>
      <button @click='submit' class="btn">提交</button>
    </div>
  </div>
</template>

<script>
import { activity_mySongs, activity_mydemo,activity_submit} from '../api/index';
export default {
  created () {
    if(this.webLoginType==1){
          this.mysong()
        this.song=true;
        this.demo=false;
    }else{
        if(this.$route.query.radio==2){
             this.mysong()
             this.radio= this.$route.query.radio
        }else{
           this.mydemo()
           this.radio= this.$route.query.radio
        }
    //   判断按钮什么时候显示
    if (this.contributionsType.split(',').length > 1) {
      // 词曲、歌曲
        this.song=true;
        this.demo=true;
    } else if (this.contributionsType.split(',')[0] == '1') {
      // 词曲
      this.song=false;
        this.demo=true;
    } else if (this.contributionsType.split(',')[0] == '2') {
      // 歌曲
       this.song=true;
      this.demo=false;
    }
    }
  },
  data () {
    return {
      radio: '2',
      flag: -1,
      songlist: [],
      msg: null,
      btnflag: false,
    //歌曲词曲
    song:false,
    demo:false,
    params: { pageNum: 1, pageSize:10 },
    totalnum:''
    }
    
  },
  computed: {
    // 获取参赛作品类型
    contributionsType () {
      return this.$store.state.Songdetails.contributionsType
    },
    // 活动id
    activityId(){
        return this.$store.state.Songdetails.id
    },
    //获取当前身份
    webLoginType(){
        return this.$store.state.user.webLoginType
    }
  },
  methods: {
    choice (val, index) {
      this.flag = index
      this.msg = val
    },
    // 我的歌曲
    mysong () {
      activity_mySongs(this.params).then((res) => {
          if(res.data==null){
              this.songlist =[]
              return
        }
        this.songlist = res.data.list
         this.totalnum=res.data.total
      })
    },
    // 我的词曲
    mydemo () {
      activity_mydemo(this.params).then(res => {
        if(res.data==null){
              this.songlist =[]
              return
        }
        this.totalnum=res.data.total
        this.songlist = res.data.list
      });
    },
    //提交
    submit(){
        if(this.btnflag){
            let p={
             busiId:this.msg.id,
             id:this.activityId,
             type:this.radio
            }
          activity_submit(p).then(res => {
              if(res.code==0){
              this.$router.push('/submitwork')
              }
              })
             
        }else
        this.$toast('请先选择作品')
    },
    change(){

        if(this.totalnum<this.params.pageNum*this.params.pageSize){
            this.params.pageNum=1;
        }else{
            this.params.pageNum+=1;
        }
      
   if(this.radio==2){
        this.mysong()
   }else{
        this.mydemo()
   }
    },
  },
  watch: {
    'radio': function (valnew) {
         this.params.pageNum=1;
      if (valnew == '2') {
        this.mysong()
      }
      if (valnew == '1') {
        this.mydemo()
      }

    },
    'msg': function(valnew){
       if(valnew==null){
         this.btnflag=false
       }else{
           this.btnflag=true
       }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/initialization.scss";
.big {
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 43px;
  .title-head {
    margin-top: 51px;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #333333;
  }
  .title-radio {
    text-align: center;
    margin-top: 31px;
    margin-bottom: 33px;
    .el-radio {
      margin: 0px 34px;
    }
  }
  .center {
    .item {
      cursor: pointer;
      width: 572px;
      height: 77px;
      border: 1px solid #e9e9e9;
      margin: 0 auto;
      padding-left: 26px;
      box-sizing: border-box;
      padding-top: 18px;
      margin-bottom: 20px;
      p:nth-of-type(1) {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: LEFT;
        color: #333333;
      }
      p:nth-of-type(2) {
        margin-top: 8px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #666666;
      }
    }
    .active {
      background: #fff5f5;
      position: relative;
      display: block;
      .horn {
        background: url("../assets/horn.png");
        background-size: 100% 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 20px;
        height: 20px;
        // border: 1px solid rgba(255, 0, 77, 0.3);
      }
    }
  }
  .footbtn {
    margin-top: 142px;
    text-align: center;
    margin-bottom: 40px;
    button {
      cursor: pointer;
      border: none;
      width: 150px;
      height: 44px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #ff004d;
      line-height: 14px;
      background: #f6f6f6;
      margin: 0px 10px;
    }
    .btn {
      background: #ff004d;
      color: #ffffff;
    }
  }
}
</style>